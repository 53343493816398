/* Component in the Scene Component to fetch 
   all ShotList data for a Scene
 * Contains the Shot component to which it passes the data
   for each Shot in the Shotlist
 * Contains the ShotListCreate component  */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance, axiosReq } from "../../../api/axiosDefaults";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/Scene.module.css";
import btnStyles from "../../../styles/Button.module.css";
import appStyles from "../../../App.module.css";
import ShotListCreate from "./ShotListCreate";
import Asset from "../../../components/Asset";
import NoResults from "../../../assets/no-results.png";
import Shot from "./Shot";
import { CLIENT_PROGRAM_HOSTNAME } from "../../../utils/config";
import useHostName from "../../../hooks/useHostName";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";

const ShotlistPage = ({ scene, setShowlist }) => {
  const currentUser = useCurrentUser();
  const host = useHostName();
  const { id } = useParams();
  const [addShot, setAddShot] = useState(false);
  const [shotlist, setShotlist] = useState({ results: [] });

  const handleMount = async () => {
    try {
      if (host === CLIENT_PROGRAM_HOSTNAME) {
        const { data } = await axiosReq(
          `/shotlists/?scene_id=${id}&ordering=day_order_number`
        );
        setShotlist(data);
      } else {
        const { data } = await axiosInstance(
          `${localStorage.getItem(
            "projectSlug"
          )}/shotlists/?scene_id=${id}&ordering=day_order_number`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
          }
        );
        setShotlist(data);
      }
    } catch (err) {
      console.log(`err ${err}`);
    }
  };

  useEffect(() => {
    handleMount();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="mb-5">
      <Row>
        <Col xs={12} className="text-center">
          <h5 className={`mb-3 pl-5 py-1 ${styles.SubTitle}`}>
            SHOTLIST
            <span
              style={{ textTransform: "none" }}
              className={`float-right ${styles.Close} pt-1`}
              onClick={() => setShowlist(false)}
            >
              Close
            </span>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {currentUser &&
            currentUser?.groups.length > 0 &&
            (currentUser?.groups[0]?.name === "Admin" ||
              currentUser?.groups[0]?.name === "Superadmin" ||
              currentUser?.groups[0]?.name === "Admincreative") && (
              <Button
                onClick={() => setAddShot((addShot) => !addShot)}
                className={`${btnStyles.Button} ${btnStyles.Bright}`}
              >
                Add Shot
              </Button>
            )}
          {!addShot ? (
            ""
          ) : (
            <ShotListCreate
              setAddShot={setAddShot}
              setShotlist={setShotlist}
              handleMount={handleMount}
              scene={scene}
            />
          )}
        </Col>
      </Row>
      {/* titles */}
      <div className="d-none d-md-block">
        <Row
          style={{ textTransform: "uppercase" }}
          className={`mt-3 ${styles.TitleBox} text-center mx-0`}
        >
          <Col className={`px-0 ${styles.TitleBox2} `} xs={1} md={1}>
            <p className="mb-0">Info</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Shot #</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Size</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Frame</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={4} md={4}>
            <p className="mb-0">Description</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Angle</p>
          </Col>
          <Col className={`px-0  ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Move</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Image</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0">Edit</p>
          </Col>
        </Row>
      </div>
      {/* mobile */}
      <div className="d-block d-md-none">
        <Row
          style={{ textTransform: "uppercase" }}
          className={`mt-3 ${styles.TitleBox} text-center mx-0`}
        >
          <Col className={`px-0 ${styles.TitleBox2} `} xs={1} md={1}>
            <p className={`ml-1 mb-0`}>In</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={1} md={1}>
            <p className="mb-0"># </p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={2} md={2}>
            <p className="mb-0">Sz/Fr</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={4} md={4}>
            <p className="mb-0">Detail</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2}`} xs={3} md={3}>
            <p className="mb-0">Ang/Mv</p>
          </Col>
          <Col className={`px-0 ${styles.TitleBox2} `} xs={1} md={1}>
            <p className="pl-1 mb-0"></p>
          </Col>
        </Row>
      </div>
      {/* shots */}
      <Row>
        <Col>
          <>
            {shotlist.results.length ? (
              shotlist.results.map((shot, index) => (
                <Shot
                  style={{
                    backgroundColor:
                      index % 3 === 0
                        ? "#dbfaf9"
                        : index % 2 === 0
                        ? "rgb(223 254 240)"
                        : "rgb(248 241 249)",
                  }}
                  key={shot.id}
                  handleMount={handleMount}
                  shotAll={shot}
                />
              ))
            ) : (
              <Container className={`pt-4 ${appStyles.Content}`}>
                <Asset src={NoResults} message="Add Shots" />
              </Container>
            )}
          </>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export default ShotlistPage;
