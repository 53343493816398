import React, { useEffect, useState } from "react";
import TopBox from "../../components/TopBox";
import useRedirect from "../../hooks/Redirect";
import useHostName from "../../hooks/useHostName";
import { useHistory, useParams, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import r1 from "../../assets/r1.png";
import styles from "../../styles/Scene.module.css";
import btnStyles from "../../styles/Button.module.css";
import { Alert, Card } from "react-bootstrap";
import { axiosInstance, axiosReq } from "../../api/axiosDefaults";

import { CLIENT_PROGRAM_HOSTNAME } from "../../utils/config";
import InfoCreate from "./info/InfoCreate";
import ImportCreate from "../scenes/info/ImportCreate";
import toast from "react-hot-toast";
import { EpisodeEditDropdown } from "./EpisodeEditDropdown";
import { useCurrentUser } from "../../contexts/CurrentUserContext";

export default function EpisodeCreateForm({ topbox }) {
  const host = useHostName();
  useRedirect();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const params = useParams();
  console.log(params);
  const [errors, setErrors] = useState({});
  const [showImp, setShowImp] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [episodes, setEpisodes] = useState([]);

  const [postData, setPostData] = useState({
    number: "",
    title: "",
  });

  const { number, title } = postData;

  const handleChange = (event) => {
    setPostData({
      ...postData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const formData = new FormData();

    // formData.append("number", number);
    const body = {
      episode_number: number,
      title: title,
      // series: params?.seriesId,
    };

    try {
      const { data } = await axiosInstance.post(
        `${localStorage.getItem("projectSlug")}/scene_episodes/`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );

      setPostData({ ...postData, number: "", title: "" });
      fetchEpisodes();
    } catch (error) {
      console.log(error);
      toast.error("Failed to create series. Try again");
    }

    // return console.log([...formData]);
  };

  const handleEpisodeDelete = async (episodeId) => {
    try {
      const { data } = await axiosInstance.delete(
        `${localStorage.getItem("projectSlug")}/scene_episodes/${episodeId}/`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );

      toast.success("Episode deleted!");
      fetchEpisodes();
      // setSeries(data.results);
    } catch (error) {
      toast.error("Failed to delete episode!");
      console.log("Delete episode error: ", error);
    }
  };

  const buttons = (
    <div className={`text-center pt-3 mb-3 pb-2 ${styles.White}`}>
      <Button
        className={`mr-3 px-5 py-1 ${btnStyles.Button} ${btnStyles.Blue}`}
        onClick={() => history.goBack()}
      >
        Cancel
      </Button>
      {currentUser?.groups[0]?.name !== "Crew" && (
        <Button
          className={`ml-3 px-5 py-1  ${btnStyles.Button} ${btnStyles.Blue}`}
          type="submit"
        >
          Create
        </Button>
      )}
    </div>
  );

  const fetchEpisodes = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${localStorage.getItem("projectSlug")}/scene_episodes/`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );

      console.log(data);

      setEpisodes(data.results);
      // setProjectId(data.project);
    } catch (error) {
      console.log("fetch series error: ", error);
    }
  };

  useEffect(() => {
    fetchEpisodes();
  }, []);

  return (
    <div>
      {topbox ? "" : <TopBox title="Create Episodes" />}
      <Row>
        {/* back bit */}
        <Col xs={4}>
          <Button
            className={`${btnStyles.Button} ${btnStyles.Blue} py-0 mt-2`}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Col>
        {/* imp but */}
        <Col xs={4} className="text-center">
          <Button
            className={`py-0 my-2 ${btnStyles.Shed} ${btnStyles.Button}`}
            onClick={() => setShowImp((showImp) => !showImp)}
          >
            IMPORTANT
          </Button>
        </Col>
        {/* info but */}
        <Col className="float-right" xs={4}>
          <Button
            className={`float-right py-0 my-2 ${btnStyles.Blue} ${btnStyles.Button}`}
            onClick={() => setShowInfo((showInfo) => !showInfo)}
          >
            INFO
          </Button>
        </Col>
      </Row>
      {/* showInfo */}
      <Row>
        <Col>{!showInfo ? "" : <InfoCreate />}</Col>
      </Row>
      {/* showImp */}
      <Row>
        <Col>{!showImp ? "" : <ImportCreate />}</Col>
      </Row>
      <h3 className="text-center">Create Episodes</h3>
      <Form className={`mb-3 ${styles.Back}`} onSubmit={handleSubmit}>
        <Row className="text-center">
          <Col
            className="d-flex justify-content-center p-0 p-md-2"
            xs={{ span: 4, offset: 4 }}
          >
            <Form.Group controlId="number" className={`${styles.Width2} `}>
              <Form.Label className={`${styles.Bold}`}>
                Episode Number
              </Form.Label>
              <Form.Control
                className={styles.Input}
                type="text"
                name="number"
                value={number}
                onChange={handleChange}
              />
            </Form.Group>
            {errors?.number?.map((message, idx) => (
              <Alert variant="warning" key={idx}>
                {message}
              </Alert>
            ))}
          </Col>
        </Row>
        <Row className="text-center">
          <Col
            className="d-flex justify-content-center p-0 p-md-2"
            xs={{ span: 4, offset: 4 }}
          >
            <Form.Group controlId="title" className={`${styles.Width2} `}>
              <Form.Label className={`${styles.Bold}`}>Title</Form.Label>
              <Form.Control
                className={styles.Input}
                type="text"
                name="title"
                value={title}
                onChange={handleChange}
              />
            </Form.Group>
            {errors?.title?.map((message, idx) => (
              <Alert variant="warning" key={idx}>
                {message}
              </Alert>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <div className={`mt-3 ${styles.Container}`}>{buttons}</div>
          </Col>
        </Row>
      </Form>

      <Row style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {episodes?.length <= 0 ? (
          <div>No episodes available.</div>
        ) : (
          <>
            {episodes?.map((episode, index) => (
              <Card
                className={`text-center ${styles.SceneCard}`}
                style={{
                  width: "200px",
                }}
                key={episode?.id}
              >
                <div className={`mb-0 px-2 py-1`}>
                  <Row className="mx-0 d-flex align-items-center ">
                    <Col className="mx-0 px-0" xs={1}></Col>
                    <Col xs={10} className="mx-0 px-0 text-center">
                      <Link
                        to={`/${localStorage.getItem(
                          "projectSlug"
                        )}/scenes?episode=${episode?.id}&project=${
                          episode?.project
                        }&episodeTitle=${episode?.title}`}
                      >
                        <div>
                          <h5 className={` ${styles.Grey}`}>
                            Episode {episode?.episode_number}
                          </h5>
                        </div>
                      </Link>
                    </Col>

                    <Col xs={1} className={`mx-0 px-0 ${styles.Drop}`}>
                      {currentUser?.groups[0]?.name !== "Crew" && (
                        <EpisodeEditDropdown
                          episodeId={episode?.id}
                          handleEpisodeDelete={handleEpisodeDelete}
                        />
                      )}
                    </Col>
                  </Row>
                  <Link
                    to={`/${localStorage.getItem(
                      "projectSlug"
                    )}/scenes?episode=${episode?.id}&project=${
                      episode?.project
                    }&episodeTitle=${episode?.title}`}
                  >
                    <div>
                      <span className={styles.Italics}>{episode?.title}</span>
                    </div>
                  </Link>
                </div>
                {/* Body */}
                <Card.Body
                  style={{
                    backgroundImage:
                      index % 3 === 0
                        ? `url(${r1})`
                        : index % 2 === 0
                        ? `url(${r1})`
                        : `url(${r1})`,
                    objectFit: "fill",
                    width: "auto",
                    repeat: "no-repeat",
                  }}
                  className="py-1 px-0"
                >
                  <Link
                    to={`/${localStorage.getItem(
                      "projectSlug"
                    )}/scenes?episode=${episode?.id}&project=${
                      episode?.project
                    }&episodeTitle=${episode?.title}`}
                  >
                    <div className={` ${styles.Div50} px-0`}>
                      {/* <span className={styles.Italics }>{title}</span> */}
                      <Card.Text
                        style={{ fontWeight: "700" }}
                        className={` ${styles.Grey} px-0`}
                      >
                        {""}
                      </Card.Text>
                    </div>
                    <div className={`px-0 ${styles.Div50}`}>
                      <p className={` ${styles.Grey}`}>{""}</p>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            ))}
          </>
        )}
      </Row>
    </div>
  );
}
